.loading-ion-content{
    --ion-background-color:#EEEEEE;
    height: 100%;
}
.loading-ion-img {
    display: block;
    max-width: 20%;
    vertical-align: baseline;
    margin-left: auto;
    margin-right: auto;
}

.loading-ion-grid {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0;
    margin:0;
    position: relative;
}

.progress-bar {
    position: fixed;
}