.custom-ion-item {
    --min-height: 57.5px;
    --border-width: 1px;
    --border-color: rgb(202, 202, 202);
    --border-radius: 5px;
}

.ion-row-icon {
    margin-bottom: 20px;
}
