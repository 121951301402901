.loading-ion-content{
    --ion-background-color:#EEEEEE;
}

.loading-ion-grid {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin:0;
    position: relative;
}

.ion-icon-404 {
    font-size: 10em;
}