.row-header{
    border-bottom: 3px solid #aeb7ca;
    border-top: 1px solid #aeb7ca;
    border-left: 1px solid #aeb7ca;
}
.ion-row-custom {
    border-bottom: 1px solid #aeb7ca;
    border-left: 1px solid #aeb7ca;
}
.ion-col-custom {
    border-right: 1px solid #aeb7ca;
}

.no-border {
    border: 0px solid #aeb7ca;
}