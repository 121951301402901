.row-header {
    border-bottom: 3px solid #aeb7ca;
    border-top: 1px solid #aeb7ca;
    border-left: 1px solid #aeb7ca;
    border-right: 1px solid #aeb7ca;
    border-radius: 8px 8px 0 0;
    background-color: #f5f6f9;
}

.ion-row-custom {
    border-bottom: 1px solid #aeb7ca;
    border-left: 1px solid #aeb7ca;
    /*border-radius: 6px;*/
}

.item-header {
    --background: #f5f6f9;
}

.ion-col-custom {
    border-right: 1px solid #aeb7ca;
}
.ion-last-col-custom {
    border-right: 0 solid #aeb7ca;
}

.no-border {
    border: 0 solid #aeb7ca;
}

.ion-item-center {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}
